import React from "react"
import Button from "../../../components/Button";
import { Link } from "gatsby";
import Layout from "../../../components/layout"
import Seo from "../../../components/Seo"
import {
  PrimaryHero,
  HeroCopyBlock
} from "../../../components/Heros"
import OurClients from "../../../components/OurClients"
import OurCarriers from "../../../components/OurCarriers"
import {
  DualComponentBlock,
  DualImageBlock
} from "../../../components/Grid"
import RequestDemo from "../../../components/RequestDemo"

import cx from "classnames"
import styles from "./styles.module.scss"

import messageIcon from "./images/default.svg"
import tickPurple from "./images/tickPurple.svg"
import tickWhite from "./images/tickWhite.svg"
import onphone from "./images/onphone.png"
import reactmessages from "./images/reactmessages.png"
import busyoffice from "./images/busyoffice.png"
import reactlaptop from "./images/reactlaptop.png"
import coffeephone from "./images/coffeephone.png"

const ProactiveLeft = () => (
  <div className={styles.proactiveLeft}>
    <h3>Get proactive with your customer communications.</h3>
    <p>Own your own branded comms, rather than handing over to carriers for delivery updates.</p>
    <div className={styles.tickPoints}>
      <div className={styles.tickPoint}>
        <img src={tickPurple} alt="purple tick"/>
        <div>
          <p><b>Seamless systems</b></p>
          <p>Integrate REACT with crucial platforms—such as your CRM or marketing automation.</p>
        </div>
      </div>
      <div className={styles.tickPoint}>
        <img src={tickPurple} alt="purple tick"/>
        <div>
          <p><b>Self-serve service</b></p>
          <p>Easily embed tracking updates to let customers self-serve through which ever channel they choose — allowing upsell on your website, livechat, email, SMS or app.</p>
        </div>
      </div>
      <div className={styles.tickPoint}>
        <img src={tickPurple} alt="purple tick"/>
        <div>
          <p><b>Don’t be reactive</b></p>
          <p>Get advanced warning of issues to manage expectations proactively, in your own brand and tone of voice.</p>
        </div>
      </div>
    </div>
    <div><Button theme="purple"><a href="#">Learn more</a></Button></div>
  </div>
)

const ProactiveRight = () => (
  <div className={styles.proactiveRight}>
    <img src={reactmessages} alt="woman on phone" className={styles.overImg}/>
    <img src={onphone} alt="woman on phone" className={styles.underImg}/>
  </div>
)

const ReactPage = () => (
  <Layout>
    <Seo title="Sorted | Products - Pro" keywords={[`Sorted`, `Products`, `Pro`]} />
    <PrimaryHero theme="pink">
      <HeroCopyBlock title={["Powerful delivery", "tracking."]}>
        <p>A single data-rich platform, aggregating all carrier information and delivery tracking into a digestible dashboard; making exceptions management and impact customer communications hassle-free.</p>
      </HeroCopyBlock>
    </PrimaryHero>
    <div className={cx("container", styles.clientImages)}>
      <OurClients />
    </div>

    <div className="bg-light">
      <div className="container">
        <div className={styles.productPoints}>
          <div className={styles.productPoint}>
            <img src={messageIcon} alt=""/>
            <div>
              <p>Mobile-ready dashboard.</p>
            </div>
          </div>
          <div className={styles.productPoint}>
            <img src={messageIcon} alt=""/>
            <div>
              <p>Customisable webhooks.</p>
            </div>
          </div>
          <div className={styles.productPoint}>
            <img src={messageIcon} alt=""/>
            <div>
              <p>Standardised carrier events.</p>
            </div>
          </div>
          <div className={styles.productPoint}>
            <img src={messageIcon} alt=""/>
            <div>
              <p>World map view.</p>
            </div>
          </div>
          <div className={styles.productPoint}>
            <img src={messageIcon} alt=""/>
            <div>
              <p>Lightweight integration.</p>
            </div>
          </div>
          <div className={styles.productPoint}>
            <img src={messageIcon} alt=""/>
            <div>
              <p>Full documentation portal</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={styles.genericSpacer}>
      <DualComponentBlock
        splitTablet
        leftComponent={<ProactiveLeft />}
        rightComponent={<ProactiveRight />}
      />
    </div>

    <div className={styles.genericSpacer}>
      <div class="container">
        <h3 className="text-center">Our carrier cloud.</h3>
        <p className="text-center">One single integration with Sorted connects you to hundreds of services from some of the biggest global carriers.</p>
        <OurCarriers />
        <div className="text-center">
          <Button theme="greyoutline"><Link to="/carriers">Learn more about carriers</Link></Button>
        </div>
      </div>
    </div>

    <div className={styles.genericSpacer}>
      <div class="container-fluid">
        <DualImageBlock img={busyoffice} theme="light" maxHeight={700}>
          <div>
            <h3>Market-leading carrier integration.</h3>
            <p>Rather than scraping carrier websites or letting customer contact teams do the leg work, we partner with leading global carriers to get the best quality, clean tracking data — as real-time as it gets and displayed beautifully in one place.</p>
            <div className={styles.tickPoints}>
              <div className={styles.tickPoint}>
                <img src={tickPurple} alt="purple tick"/>
                <div>
                  <p><b>Remove carrier ambiguity</b></p>
                  <p>Obscure carrier states are translated into consistent, on-brand language.</p>
                </div>
              </div>
              <div className={styles.tickPoint}>
                <img src={tickPurple} alt="purple tick"/>
                <div>
                  <p><b>Single window into all carrier ops</b></p>
                  <p>Get an efficient and catch-all view of customer parcel states.</p>
                </div>
              </div>
              <div className={styles.tickPoint}>
                <img src={tickPurple} alt="purple tick"/>
                <div>
                  <p><b>Less leg work</b></p>
                  <p>Exception enquiries can be  automated, meaning the admin  of carrier escalations is  streamlined.</p>
                </div>
              </div>
            </div>
            <Button theme="purple"><a href="https://www.test.com">Learn more</a></Button>
          </div>
        </DualImageBlock>
      </div>
    </div>

    <div className={styles.genericSpacer}>
      <div className={styles.reactDemo}>
        <div className={styles.reactDemoOverlay} />
        <div class="container">
          <div className={styles.reactDemoTop}>
            <div className={styles.reactDemoTopInner}>
              <div className={styles.tickPoints}>
                <div className={styles.tickPoint}>
                  <img src={tickPurple} alt="purple tick"/>
                  <div>
                    <p><b>Remove carrier ambiguity</b></p>
                    <p>Obscure carrier states are translated into consistent, on-brand language.</p>
                  </div>
                </div>
                <div className={styles.tickPoint}>
                  <img src={tickPurple} alt="purple tick"/>
                  <div>
                    <p><b>Single window into all carrier ops</b></p>
                    <p>Get an efficient and catch-all view of customer parcel states.</p>
                  </div>
                </div>
                <div className={styles.tickPoint}>
                  <img src={tickPurple} alt="purple tick"/>
                  <div>
                    <p><b>Less leg work</b></p>
                    <p>Exception enquiries can be  automated, meaning the admin  of carrier escalations is  streamlined.</p>
                  </div>
                </div>
              </div>
              <img src={reactlaptop} className={styles.reactlaptop} />
            </div>
          </div>
        </div>
        <div className={styles.reactDemoBottom}>
          <div class="container">
            <div className={styles.reactDemoBottomInner}>
              <div>
                <h3>Dynamic insights dashboard.</h3>
                <p>Trawling through difficult sites or disparate systems to serve your customers isn’t a good use of valuable time. Regardless of the number of carriers, the REACT dashboard is concise and easy to navigate.</p>
              </div>
              <div>
                <Button theme="white"><a href="#">Learn more</a></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={styles.genericSpacer}>
      <div class="container-fluid">
        <h3 className="text-center">Competetive advantage.</h3>
        <p className="text-center">It’s not good enough to meet customer delivery expectations, the real win is to exceed them. Be the one to bring the change in your organisation—with minimal IT resource needed for lightweight integration.</p>
        <div className={styles.competetiveTri}>
          <div className={cx(styles.competetiveItem, styles.competetiveItemGrey)}>
            <div className={styles.tickPoint}>
              <img src={tickPurple} alt="purple tick"/>
              <div>
                <p><b>Own your upsell</b></p>
                <p>Keep customers on your site and make the ‘my account’ section work harder, with upsell opportunities on tracking pages.</p>
              </div>
            </div>
          </div>
          <div className={cx(styles.competetiveItem, styles.competetiveItemImg)}>
            <img src={coffeephone} alt="woman drinking a bru on phone"/>
            <div className={styles.tickPoint}>
              <img src={tickWhite} alt="purple tick"/>
              <div>
                <p><b>Keep and convert customers</b></p>
                <p>Increase loyalty and lower cost of acquisition by keeping customers happy and engaged.</p>
              </div>
            </div>
          </div>
          <div className={cx(styles.competetiveItem, styles.competetiveItemPurple)}>
            <div className={styles.tickPoint}>
              <img src={tickPurple} alt="purple tick"/>
              <div>
                <p><b>Instantly upgrade delivery proposition</b></p>
                <p>With one integration giving full access to our carrier cloud, and a differentiated customer offering.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <RequestDemo />

  </Layout>
)

export default ReactPage
